<template>
  <div v-if="items.length > 0">
    <vue-perfect-scrollbar
      v-once
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
      v-if="!loading"
    >
      <b-link v-for="(notification, idx) in items" :key="`notification-${idx}`"
      @click="gotoRoute(notification)"
      >
        <b-media class="bg-light-dark">
          <!-- <template #aside>
          <b-avatar
            size="32"
            :variant="getNotificationStatus(notification.type).color"
          >
            <feather-icon
              :icon="getNotificationStatus(notification.type).icon"
            />
          </b-avatar>
        </template> -->
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ getTranslate(notification, "title", $i18n.locale) }}
            </span>
          </p>
          <small class="notification-text">{{
            getTranslate(notification, "message", $i18n.locale)
          }}</small>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>
  </div>
  <div v-else class="text-center py-4">
    <feather-icon icon="XIcon"></feather-icon>
    <h5 class="text-muted">No Notification</h5>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";
import Repository from "@/repositories/RepositoryFactory";
import { getTranslate, getNotificationStatus } from "@/libs/helper";
const NotificationRepository = Repository.get("notification");

import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BAvatar,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
    FeatherIcon,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      items: [],
      loading: false,
      user: JSON.parse(localStorage.getItem("userData")),
      getNotificationStatus,
      getTranslate,
    };
  },
  created() {
    this.listNotification();
  },

  watch: {
    $route(to, from) {},
  },
  props: {
    hideDropDown: Function,
  },

  methods: {
    listNotification() {
      this.loading = true;
      NotificationRepository.index()
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.items = [...data];
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    gotoRoute(data) {
      if (getNotificationStatus("requester") && this.user.canReviewFinal) {
        this.$router.replace({
          name: getNotificationStatus("hod").route,
          params: { id: data.dataId },
        });
        this.hideDropDown();
      } else {
        this.$router.replace({
          name: getNotificationStatus(data.type).route,
          params: { id: data.dataId },
        });
        this.hideDropDown();
      }
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };
    return {
      perfectScrollbarSettings,
    };
  },
};
</script>

<style>
</style>