<template>
  <b-nav-item-dropdown
    ref="dropdown"
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <div @click="readAllNotification">
        <feather-icon
        :badge="count"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
        
      />
      </div>
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">Notifications</h4>
        <b-badge v-show="count.data > 0" pill variant="light-primary">
          {{count}} New
        </b-badge>
      </div>
    </li>
    <notification :hideDropDown="hideDropdown"></notification>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BAvatar,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Notification from "@/views/notification/Notification.vue";
import Repository from "@/repositories/RepositoryFactory";
import axios from "axios";
const NotificationRepository = Repository.get("notification");
export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    BButton,
    BFormCheckbox,
    Notification,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      count:'',
      loading: false,
    };
  },
  created() {
    this.countNotification();
  },

  methods: {
    countNotification() {
      this.loading = true;
      NotificationRepository.count()
        .then((response) => {
          const data = response?.data;
          if (data) {
            this.count = data.data;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    readAllNotification(){
      this.count = '';
      NotificationRepository.readAll();

    },
    hideDropdown(){
      this.$refs.dropdown.hide();
    }
  },
};
</script>

<style>
</style>
